import React, {
	useState,
	useEffect,
	useCallback,
} from "react";
import { Route, Routes } from "react-router-dom";
import {
	createGlobalStyle,
	ThemeProvider,
} from "styled-components";

// Screens
import TopNavbar from "./components/nav/TopNavBar";
import Footer from "./components/sections/Footer";
import Landing from "./screens/Landing";
import NotFound from "./screens/NotFound";
import Experience from "./components/sections/Experience";
import WeatherApp from "./screens/WeatherApp";
import Spotify from "./screens/Spotify";
import WashGuard from "./screens/WashGuard";
import TradeMe from "./screens/TradeMe";
import BackToTop from "./components/elements/BackToTop";

const lightTheme = {
	body: "#fff",
	text: "rgb(17 16 16)",
};

const darkTheme = {
	body: "rgb(17 16 16)",
	text: "#fff",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) =>
			props.theme.body};
    color: ${(props) => props.theme.text};
    transition: color 0.3s linear;

    a {
      color: ${(props) => props.theme.text};
      text-decoration: none;
      transition: color 0.3s linear;

      &:hover {
        color: #BFAA8C;
      }
    }
  }
    
`;

export default function App() {
	const [theme, setTheme] = useState("light");

	useEffect(() => {
		const localTheme =
			localStorage.getItem("theme");
		if (localTheme) {
			setTheme(localTheme);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("theme", theme);
	}, [theme]);

	const toggleTheme = useCallback(() => {
		setTheme((prevTheme) =>
			prevTheme === "light" ? "dark" : "light",
		);
	}, []);

	return (
		<>
			<ThemeProvider
				theme={
					theme === "light"
						? lightTheme
						: darkTheme
				}
			>
				<GlobalStyle />
				<TopNavbar
					toggleTheme={toggleTheme}
					theme={theme}
				/>

				<BackToTop />
				<Routes>
					<Route path="/" element={<Landing />} />
					<Route
						path="/resume"
						element={<Experience />}
					/>
					<Route
						path="/weather-app"
						element={<WeatherApp />}
					/>
					<Route
						path="/spotify-api"
						element={<Spotify />}
					/>
					<Route
						path="/washguard"
						element={<WashGuard />}
					/>
					<Route
						path="/trademe"
						element={<TradeMe />}
					/>
					<Route
						path="*"
						element={<NotFound />}
					/>
				</Routes>
				<Footer />
			</ThemeProvider>
		</>
	);
}
