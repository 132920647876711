import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

// Components
import FullButton from "../buttons/FullButton";

export default function Header() {
	return (
		<Wrapper id="home" className="container">
			<LeftSide>
				<div>
					<h2> Kia Ora, my name is </h2>
					<h1 className="font60">
						Halmai McRae{" "}
					</h1>
					<h2>I build things for the web</h2>
					<br></br>
					<p className="font15">
						Software developer based in Te
						Whanganui-a-Tara. Specialising in
						websites, applications and everything
						in between.
					</p>
					<br />
					<ButtonsRow className="flexNullCenter">
						<div
							style={{
								width: "190px",
							}}
						>
							<Link
								activeclass="active"
								to="/#contact"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								<FullButton
									title="Get in touch"
									border={true}
								/>
							</Link>
						</div>
						<div
							style={{
								width: "190px",
								marginLeft: "15px",
							}}
						>
							<Link
								activeclass="active"
								to="/#projects"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								<FullButton
									title="View my work"
									border={false}
								/>
							</Link>
						</div>
					</ButtonsRow>
				</div>
			</LeftSide>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	display: flex;
	align-items: center;
	min-height: 100vh;
	padding: 0 5%;
	@media (max-width: 960px) {
		padding: 20% 10%;
	}
`;

const LeftSide = styled.div`
	width: 70%;
	height: 100%;
	@media (max-width: 960px) {
		width: 100%;
		order: 2;
		text-align: left;
	}
`;

const ButtonsRow = styled.div`
	padding-top: 10px;
	@media (max-width: 960px) {
		justify-content: start;
		padding: 10% 0;
	}
`;
