import React from 'react'
import styled from 'styled-components'

export default function FullButton({
  title,
  action,
  border,
}) {
  return (
    <Wrapper
      className="animate pointer"
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  )
}

const Wrapper = styled.button`
white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  border-radius: 0px;
  font-size: 16px;
  border: 1px solid
  ${(props) =>
    props.border ? '#BFAA8C' : '#BFAA8C'};
  background-color: ${(props) =>
    props.border ? 'transparent' : '#BFAA8C'};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) =>
    props.border ? '#BFAA8C' : '#fff'};
  :hover {
    background-color: ${(props) =>
      props.border
        ? 'rgba(217, 150, 91, 0.1)'
        : 'rgba(217, 150, 91, 0.1)'};
    border: 1px solid #BFAA8C;
    color: ${(props) =>
      props.border ? '#BFAA8C' : '#BFAA8C'};
  }
  
  }
`
