import React from 'react'
import styled from 'styled-components'

export default function Images(props) {
  return (
    <Wrapper>
      <img src={props.img} alt={props.alt} />
    </Wrapper>
  )
}
const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 500px;
    width: 100%;
  }
`
