import React, { useState, useEffect } from 'react'

export default function BackToTop() {
  const [isVisible, setIsVisible] =
    useState(false)

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 600) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener(
      'scroll',
      toggleVisibility
    )

    return () => {
      window.removeEventListener(
        'scroll',
        toggleVisibility
      )
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div
      className={`scroll-to-top ${
        isVisible ? 'scroll-to-top-visible' : ''
      }`}
      onClick={isVisible ? scrollToTop : null}
    >
      {isVisible && (
        <i className="fas fa-arrow-up font20"></i>
      )}
    </div>
  )
}
