import React from 'react'
import styled from 'styled-components'

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <Wrapper>
      <div>
        <div className="container">
          <InnerWrapper className="flexSpaceCenter">
            <StyleP className="font13">
              © {getCurrentYear()} Designed and
              developed by Halmai McRae | All
              rights reserved
            </StyleP>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InnerWrapper = styled.div`
  padding: 20px 0;
  @media (max-width: 550px) {
    flex-direction: row;
  }
`
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
    text-align: center;
  }
`
