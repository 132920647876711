import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import Title from "../components/elements/Title";
import FullButton from "../components/buttons/FullButton";
import Body from "../components/elements/Body";
import Images from "../components/elements/Images";
import spotifyApiBanner from "../assets/images/spotifyApiBanner.svg";
import spotifyApiMock from "../assets/images/spotifyApiMock.svg";

// Images

export default function Spotify() {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper className="whiteBg">
			<div className="container">
				<HeaderInfo>
					<Title
						title="Spotify API Integration"
						desc1="#ShareMyMixtape is a music application designed for music lovers who enjoy creating and sharing personalized mixtapes. The app integrates with Spotify's Web API to access users' music data and generate mixtapes based on their top tracks."
						warning="This project is currently under review for an extended
            quota limit from Spotify which can take up to 6 weeks. Please fill 
            out the form with your Spotify associated email address to request
            a demo."
					/>
				</HeaderInfo>
				<Images
					img={spotifyApiMock}
					alt={"project image cover"}
				/>
				<ButtonsRow className="flexNullCenter">
					<div style={{ width: "190px" }}>
						<a
							href="https://sharemymixtape.herokuapp.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="View"
								border={true}
							/>
						</a>
					</div>
					<div
						style={{
							width: "190px",
							marginLeft: "15px",
						}}
					>
						<a
							href="https://github.com/halmai-mcrae/share-my-mixtape"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="Code"
								border={false}
							/>
						</a>
					</div>
				</ButtonsRow>
				<Body
					title={"Overview"}
					desc={`To use #ShareMyMixtape, users log in with their Spotify account, which allows the app to access their music data. The authentication process is done through the Spotify Web API Authorization Code Flow, a secure and standard way to authorise third-party applications to access user data. Once the user is authenticated, the app requests the user's top tracks for a specific time period (this month, the previous six months, or this year) using the Spotify API. The app then uses an algorithm to select the top tracks and create a mixtape playlist that captures the user's musical preferences. The mixtape can be saved and played within the Spotify app or shared with friends on social media.`}
					desc1={`In addition to the mixtape playlist, #ShareMyMixtape generates a personalised cover image using the Spotify API's image endpoints. The cover image features the user's Spotify profile picture and the mixtape title, creating a unique and personalized visual representation of their music taste.`}
					desc2={`#ShareMyMixtape is designed to provide a nostalgic experience of creating and sharing mixtapes in a modern digital format. The app is perfect for music enthusiasts who want to check their top tracks, rediscover old favourites, and share their musical tastes with others. By integrating with Spotify's API, #ShareMyMixtape enables users to access their music data in a new and creative way, adding a fun and personal touch to their music listening experience.`}
				/>
				<br />
				<br />

				<Images
					img={spotifyApiBanner}
					alt={"project image cover"}
				/>

				<Body title={"Tools"} />
				<div className="grid-container">
					<ul>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							JavaScript
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Bootstrap
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Express.js
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Node.js
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							REST APIs
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Heroku CLI
						</li>
					</ul>
				</div>
				<div
					style={{
						width: "150px",
						paddingTop: "2rem",
					}}
				>
					<FullButton
						title="Back"
						border={false}
						action={() => {
							navigate(-1);
						}}
					/>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	padding: 10% 10%;
	width: 100%;
	min-height: 100vh;

	.grid-container {
		margin-top: -40px;
		display: grid;
	}
	li {
		line-height: 3.5rem;
	}
	span {
		color: #bfaa8c;
	}
`;

const HeaderInfo = styled.div`
        @media (max-width: 860px) {
          text - align: left;
  }
        `;

const ButtonsRow = styled.div`
margin-top: 5% ;
        @media (max-width: 860px) {
          justify - content: space-evenly;
  }
        `;
