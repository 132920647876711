import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

//Components
import FullButton from "../buttons/FullButton";

export default function Form() {
	const [from_name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [showAlert, setShowAlert] =
		useState(false);

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		emailjs
			.sendForm(
				"service_sahxy0f",
				"template_hnp3l95",
				event.target,
				"ZV0_wKZ_PpgWgVlnf",
			)
			.then(
				(result) => {
					console.log(result.text);
					setName("");
					setPhone("");
					setEmail("");
					setMessage("");
					setShowAlert(true);
				},
				(error) => {
					console.log(error.text);
				},
			);
	};

	return (
		<FormWrapper onSubmit={handleSubmit}>
			<Label>
				<Input
					type="from_name"
					name="from_name"
					placeholder="Name"
					value={from_name}
					onChange={(event) =>
						setName(event.target.value)
					}
					required
				/>
			</Label>
			<Label>
				<Input
					type="tel"
					name="phone"
					placeholder="Mobile"
					value={phone}
					onChange={(event) =>
						setPhone(event.target.value)
					}
					required
				/>
			</Label>
			<Label>
				<Input
					type="email"
					name="reply_to"
					placeholder="Email"
					value={email}
					onChange={(event) =>
						setEmail(event.target.value)
					}
					required
				/>
			</Label>
			<Label>
				<TextArea
					type="text"
					name="message"
					placeholder="Message..."
					rows={5}
					value={message}
					onChange={(event) =>
						setMessage(event.target.value)
					}
					required
				/>
			</Label>
			<div style={{ width: "190px" }}>
				<FullButton
					type="submit"
					title="Submit"
					border={false}
				/>
				{showAlert && (
					<AlertModal>
						<AlertContent>
							<h4>
								Sent. I'll be in touch soon!
							</h4>

							<button
								title="Close"
								border={true}
								onClick={handleAlertClose}
							>
								Close
							</button>
						</AlertContent>
					</AlertModal>
				)}
			</div>
		</FormWrapper>
	);
}

const FormWrapper = styled.form`
	max-width: 100%;
	margin-top: 2rem;
`;

const Label = styled.label`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
`;

const Input = styled.input`
	font-family: "Montserrat", sans-serif;
	border: #333 1px solid;
	background-color: transparent;
	border-radius: 0px;
	padding: 10px;
	color: grey;

	:focus {
		outline: none;
		color: grey;
	}
`;

const TextArea = styled.textarea`
	font-family: "Montserrat", sans-serif;
	border: #333 1px solid;
	background-color: transparent;
	border-radius: 0px;
	padding: 10px;
	color: grey;
	:focus {
		outline: none;
		color: grey;
	}
`;

const AlertModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;
const AlertContent = styled.div`
  background-color: #fff;
  color: #333;
  padding: 40px;
  border: none;
  border-radius: 0px;
  text-align: center;
  position: fixed;

  button {
  white-space: nowrap;
  font-family: 'Montserrat', sans-serif;
  color: white;
  background-color: rgb(191, 170, 140);
  border: none;
  border-radius: 0px;
  width: 190px;
  padding: 15px;
  margin-top: 20px;
    }
  }

  @media (max-width: 550px) {
    padding: 25px;
    font-size: 12px;
  }
}`;
