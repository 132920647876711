import React from 'react'
import styled from 'styled-components'

//Components
import Form from './Form'

export default function Contact() {
  return (
    <Wrapper id="contact" className="container">
      <LeftSide>
        <h1 className="font40 bold">Contact</h1>
        <FormContainer>
          <h2>Lets talk</h2>
          <Form />
        </FormContainer>
      </LeftSide>
      <RightSide>
        <ContactLinks className="font18 regular">
          <h2>Links</h2>
          <br />
          <ul>
            <li>
              <a
                href="https://github.com/halmai-mcrae"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkIcon
                  className="fa fa-github"
                  alt="GitHub"
                />
                halmai-mcrae
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/halmai-mcrae/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkIcon className="fa fa-linkedin" />
                halmai-mcrae
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@halmaimcrae"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkIcon
                  className="fa fa-medium"
                  alt="Medium"
                />
                @halmaimcrae
              </a>
            </li>
            <li>
              <a href="tel:+64225600278">
                <ContactLinkIcon
                  className="fa fa-phone"
                  alt="Phone"
                />
                +64 22 560 0278
              </a>
            </li>
            <li>
              <a
                href="mailto:hello@halmaimcrae.co.nz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkIcon
                  className="fa fa-envelope"
                  alt="Email"
                />
                hello@halmaimcrae.co.nz
              </a>
            </li>
          </ul>
        </ContactLinks>
      </RightSide>
    </Wrapper>
  )
}
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 4% 4%;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 10% 10%;
  }

  @media (max-width: 560px) {
    .radius8 {
      width: 5%;
    }
  }
`

const LeftSide = styled.div`
  flex: 1;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    text-align: left;
  }
`

const RightSide = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 3em;
  padding-left: 14em;

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    justify-content: flex-start;
    padding-left: 0;
  }
`

const FormContainer = styled.div`
  width: 100%;
`

const ContactLinks = styled.div`
  li {
    line-height: 3.5rem;
  }
`

const ContactLinkIcon = styled.i`
  margin: 10px;
`
