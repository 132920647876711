import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import Title from "../components/elements/Title";
import FullButton from "../components/buttons/FullButton";
import Body from "../components/elements/Body";
import Images from "../components/elements/Images";

// Images
import washGuardMock from "../assets/images/washGuardMock.svg";

export default function WashGuard() {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper className="whiteBg">
			<div className="container">
				<HeaderInfo>
					<Title
						title="Wash Guard New Zealand"
						desc1=" Built with React, this business application
            includes a customer enquiry form and a modern
            user interface."
						desc2=""
					/>
				</HeaderInfo>
				<Images
					img={washGuardMock}
					alt={"project image cover"}
				/>
				<ButtonsRow className="flexNullCenter">
					<div style={{ width: "190px" }}>
						<a
							href="https://washguard.co.nz/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="View"
								border={true}
							/>
						</a>
					</div>
					<div
						style={{
							width: "190px",
							marginLeft: "15px",
						}}
					>
						<a
							href="https://github.com/halmai-mcrae/washguard"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="Code"
								border={false}
							/>
						</a>
					</div>
				</ButtonsRow>
				<Body
					title={"Overview"}
					desc={`Wash Guard Maintenance site was designed and built to suit the specific needs of the business owner. Framer Motion was incorporated to navigate the site easily and lead customers to the information they need, while also giving the site a sleek look and feel.
          `}
					desc1={`To meet the client's needs for efficient communication with their customers, I integrated EmailJS, allowing emails to be sent using API calls from the client-side. EmailJS provides an intuitive web-based dashboard where the client can can create email templates, set up email triggers and recipients, and monitor email delivery status, increasing engagement and improving overall customer satisfaction. `}
				/>

				<Body title={"Tools"} />
				<div className="grid-container">
					<ul>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							React
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							JavaScript
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Bootstrap
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Framer Motion
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							EmailJS
						</li>
					</ul>
				</div>
				<div
					style={{
						width: "150px",
						paddingTop: "2rem",
					}}
				>
					<FullButton
						title="Back"
						border={false}
						action={() => {
							navigate(-1);
						}}
					/>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	padding: 10% 10%;
	width: 100%;
	.grid-container {
		margin-top: -40px;
		display: grid;
	}
	li {
		line-height: 3.5rem;
	}
	span {
		color: #bfaa8c;
	}
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
  text - align: left;}
`;

const ButtonsRow = styled.div`
margin-top: 5% ;
  @media (max-width: 860px) {
  justify - content: space-evenly;
  }
`;
