import React from "react";
import styled from "styled-components";

// Components
import PricingTable from "../elements/PricingTable";
import FullButton from "../buttons/FullButton";

export default function Pricing() {
	return (
		<div id="top">
			<Wrapper id="resume" className="container">
				<HeaderInfo>
					<h1 className="font40 bold">
						Experience
					</h1>
					<br />
					<div style={{ width: "190px" }}>
						<a
							href="/HalmaiMcRaeResume.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="Download Resume"
								border={false}
							/>
						</a>
					</div>
				</HeaderInfo>

				<TablesWrapper className="flexSpaceNull darkBg">
					<TableBoxLarge>
						<PricingTable
							title="Integration Developer"
							text="ANZ Bank | June 2023 - Present"
							offers={[
								{
									name: "Integrate software systems and cloud applications across domains.",
									checked: true,
								},
								{
									name: "Create templates, frameworks and documentaion for other development teams in Technology.",
									checked: true,
								},
								{
									name: "Build, maintain and deploy Node.js mediation services.",
									checked: true,
								},
								{
									name: "Utilise DataPower, Jenkins and Codefresh for efficient and reliable deployment processes.",
									checked: true,
								},
								{
									name: "Understand and comply with ANZ security and banking compliance standards.",
									checked: true,
								},
							]}
						/>
					</TableBoxLarge>
				</TablesWrapper>

				<TablesWrapper className="flexSpaceNull">
					<TableBox>
						<PricingTable
							title="Intern Full Stack Developer"
							text="Trade Me | Nov 2022 - Feb 2023"
							offers={[
								{
									name: "Demonstrated adaptability when working across the tech stack in Consumer Marketplace.",
									checked: true,
								},
								{
									name: "Quickly learned and utilized C# to create mock endpoints for Jobs and Motors listings, leading to increased efficiency in the Marketing API.",
									checked: true,
								},
								{
									name: "Configured and implemented a Time-To-Live (TTL) policy for expired documents in a Firestore database on GCP to ensure efficient data management.",
									checked: true,
								},
								{
									name: "Removed deprecated code and implemented modern CSS Grid solutions to enhance user experience and improve page layout, resulting in an optimised user interface.",
									checked: true,
								},
								{
									name: "Actively participated in team planning and agile ceremonies, demonstrating strong collaboration and communication skills.",
									checked: true,
								},
							]}
						/>
					</TableBox>
					<TableBox>
						<PricingTable
							title="Student Full Stack Developer"
							text="Dev Academy Aotearoa | Jan 2022 - May 2022"
							offers={[
								{
									name: "Learned and implemented new technologies daily in a distanced learning(remote) setting.",
									checked: true,
								},
								{
									name: "Participated in an in-depth human skills program with a focus on giving and receiving feedback, difficult conversations, bias mitigation, active listening, and effective communication in teams.",
									checked: true,
								},
								{
									name: "Pair programming and team projects, following agile methodologies.",
									checked: true,
								},
								{
									name: "Constant reflection on concepts to adapt and accelerate self-directed learning.",
									checked: true,
								},
								{
									name: "Javascript (ES6), React, Redux, Version Control(Git & GitHub), HTML5, CSS, Node.js, Express, APIs(REST), SQLite, SQL, Knex.js, Heroku deployment, Auth0, Stripe, TDD with Jest, Supertest and React Testing Library.",
									checked: true,
								},
							]}
						/>
					</TableBox>
				</TablesWrapper>

				<HeaderInfo>
					<h1 className="font40 bold">
						Volunteer
					</h1>
				</HeaderInfo>

				<TablesWrapper className="flexSpaceNull darkBg">
					<TableBoxLarge>
						<PricingTable
							title="Alumni Panel Member"
							text="Summer of Tech | April 2023 - Sept 2023"
							offers={[
								{
									name: "Participate in panel discussions aimed at sharing valuable insights and advice for students interested in the SoT program.",
									checked: true,
								},
							]}
						/>
					</TableBoxLarge>
				</TablesWrapper>
			</Wrapper>
		</div>
	);
}

const Wrapper = styled.section`
	width: 100%;
	padding: 10% 10%;
	min-height: 100vh;
`;
const HeaderInfo = styled.div`
	margin-top: 2rem;
`;
const TablesWrapper = styled.div`
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const TableBox = styled.div`
	width: 31%;
	@media (max-width: 860px) {
		width: 100%;
		max-width: 370px;
		margin: 0 auto;
	}
`;
const TableBoxLarge = styled.div`
	width: 100%;
	@media (max-width: 860px) {
		width: 100%;
		max-width: 370px;
		margin: 0 auto;
	}
`;
