import React, {
	useEffect,
	useState,
} from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";

// Components
import Sidebar from "./Sidebar";
import Backdrop from "../elements/Backdrop";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar({
	toggleTheme,
	theme,
}) {
	const [y, setY] = useState(window.scrollY);
	const [sidebarOpen, toggleSidebar] =
		useState(false);

	useEffect(() => {
		window.addEventListener("scroll", () =>
			setY(window.scrollY),
		);
		return () => {
			window.removeEventListener("scroll", () =>
				setY(window.scrollY),
			);
		};
	}, [y]);

	return (
		<>
			<Sidebar
				sidebarOpen={sidebarOpen}
				toggleSidebar={toggleSidebar}
			/>
			{sidebarOpen && (
				<Backdrop toggleSidebar={toggleSidebar} />
			)}

			<Wrapper
				className="flexCenter"
				style={
					y > 100
						? { height: "60px" }
						: { height: "80px" }
				}
			>
				<ToggleButton
					title="Toggle theme"
					onClick={toggleTheme}
				>
					{theme === "light" ? (
						<i class="fas fa-toggle-off"></i>
					) : (
						<i class="fas fa-toggle-on"></i>
					)}
				</ToggleButton>
				<NavInner className="container flexSpaceCenter">
					<span></span>
					<BurgerWrapper
						title="Open menu"
						className="pointer"
						onClick={() =>
							toggleSidebar(!sidebarOpen)
						}
					>
						<BurgerIcon />
					</BurgerWrapper>
					<UlWrapper className="flexNullCenter">
						<li className="regular font20 pointer">
							<Link
								activeclass="active"
								style={{
									padding: "10px 15px",
								}}
								to="/#home"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								Home
							</Link>
						</li>
						<li className="regular font20 pointer">
							<Link
								activeclass="active"
								style={{
									padding: "10px 15px",
								}}
								to="/#about"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								About
							</Link>
						</li>

						<li className="regular font20 pointer">
							<Link
								activeclass="active"
								style={{
									padding: "10px 15px",
								}}
								to="/#projects"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								Projects
							</Link>
						</li>
						<li className="regular font20 pointer">
							<Link
								activeclass="active"
								style={{
									padding: "10px 15px",
								}}
								to="/#contact"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								Contact
							</Link>
						</li>
						<li className="regular font20 pointer">
							<Link
								activeclass="active"
								style={{
									padding: "10px 15px",
								}}
								to="/resume#top"
								spy={"true"}
								smooth={true}
								offset={-80}
							>
								Experience
							</Link>
						</li>
					</UlWrapper>
				</NavInner>
			</Wrapper>
		</>
	);
}

const Wrapper = styled.nav`
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	position: fixed;
	a {
		text-decoration: none !important;
	}
`;
const NavInner = styled.div`
	height: 100%;
	position: relative;
`;
const BurgerWrapper = styled.button`
	border: 0px;
	padding: 0 0.5%;
	outline: none;
	display: none;
	height: 100%;
	background-color: transparent;
	@media (max-width: 760px) {
		display: block;
	}
`;
const UlWrapper = styled.ul`
	display: flex;
	@media (max-width: 760px) {
		display: none;
	}
`;

const ToggleButton = styled.button`
	padding: 0 3%;
	font-size: 1.3rem;
	background: none;
	border: none;
	cursor: pointer;
	color: ${(props) => props.theme.text};
	transition: all 0.3s ease;
`;
