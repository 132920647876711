import React from 'react'
// Sections
import Header from '../components/sections/Header'
import About from '../components/sections/About'
import Projects from '../components/sections/Projects'
import Contact from '../components/sections/Contact'

export default function Landing() {
  return (
    <>
      <Header />
      <About />

      <Projects />
      <Contact />
    </>
  )
}
