import React from 'react'
import styled from 'styled-components'

export default function NotFound() {
  return (
    <div>
      <Wrapper className="container">
        <HeaderInfo>
          <h1 className="font40 bold ">404</h1>
          <h2 className="font25 extraBold">
            This page could not be found
          </h2>
        </HeaderInfo>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 15%;
`

const HeaderInfo = styled.div`
  text-align: center;
  top: 50%;
  left: 50%;
`
