import React from "react";
import styled from "styled-components";

export default function ProjectBox({
	img,
	title,
	sub,
	text,
	action,
}) {
	return (
		<Wrapper>
			<ImgBtn
				className="aniamte pointer"
				onClick={action ? () => action() : null}
			>
				<Img
					className="radius"
					src={img}
					alt="project"
				></Img>
			</ImgBtn>
			<h1 className="box-header font20">
				{title}
			</h1>
			<h2 className="box-header font18">{sub}</h2>
			<p className="box-header font15 text-left">
				{text}
			</p>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	margin: 10px 10px 30px 10px;
	.box-header {
		margin: 5px;
	}
`;
const ImgBtn = styled.button`
	border: 0px;
	padding: 0px;
	margin: 0px;
	outline: none;
	cursor: pointer;
	background-color: transparent;

	> img {
		filter: grayscale(100%);
		transition: filter 0.3s ease-in-out;
	}

	:hover > img {
		filter: grayscale(0);
	}
`;

const Img = styled.img`
	width: 400px;
	height: 220px;
	max-width: 100%;
	object-fit: cover;
`;
