import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import ProjectBox from "../elements/ProjectBox";

// Images
import weatherAppPrev from "../../assets/images/weatherAppPrev.svg";
import spotifyApiPrev from "../../assets/images/spotifyApiPrev.svg";
import washGuardPrev from "../../assets/images/washGuardPrev.svg";
import tradeMePrev from "../../assets/images/tradeMePrev.svg";

export default function Projects() {
	const navigate = useNavigate();

	return (
		<div className="whiteBg">
			<Wrapper
				id="projects"
				className="container"
			>
				<h1 className="font40 extraBold">
					Projects
				</h1>
				<div className="row textCenter">
					{/* For each project, adjust the col classes */}
					<div className="col-12 col-sm-12 col-md-12 col-lg-4">
						<ProjectBox
							img={tradeMePrev}
							title="Marketing API development"
							text="Developer | 2023 | C# | .NET | Angular "
							action={() => {
								navigate(`/trademe`);
							}}
						/>
					</div>
					<div className="col-12 col-sm-12 col-md-12 col-lg-4">
						<ProjectBox
							img={spotifyApiPrev}
							title="Spotify Web API integration"
							text="Developer | 2023| Spotify Web API"
							action={() => {
								navigate(`/spotify-api`);
							}}
						/>
					</div>
					<div className="col-12 col-sm-12 col-md-12 col-lg-4">
						<ProjectBox
							img={weatherAppPrev}
							title="OpenWeather API integration"
							text="Developer | 2022| GeoDB Cities API"
							action={() => {
								navigate(`/weather-app`);
							}}
						/>
					</div>
					<div className="col-12 col-sm-12 col-md-12 col-lg-4">
						<ProjectBox
							img={washGuardPrev}
							title="Commercial Business Website"
							text="Developer | 2022 | React | JavaScript"
							action={() => {
								navigate(`/washguard`);
							}}
						/>
					</div>
					{/* Ensure you adjust the col classes for all ProjectBoxes */}
				</div>
			</Wrapper>
		</div>
	);
}

const Wrapper = styled.section`
	width: 100%;
	min-height: 100vh;
	padding: 4% 4%;
	h1 {
		margin-bottom: 1.5rem;
	}
	@media (max-width: 960px) {
		padding: 10%;
	}
`;
