import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import Title from "../components/elements/Title";
import FullButton from "../components/buttons/FullButton";
import Body from "../components/elements/Body";
import Images from "../components/elements/Images";

// Images
import tradeMeMock from "../assets/images/tradeMeMock.svg";

export default function TradeMe() {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper className="whiteBg">
			<div className="container">
				<HeaderInfo>
					<Title
						title="Trade Me Marketing API"
						desc1="Connecting Kiwi to create the life they want"
						desc2="During my internship at Trade Me, I had the opportunity to work on the Marketing API that sends targeted marketing emails to it's members, with the aim of providing users with more personalised and relevant content."
					/>
				</HeaderInfo>
				<Images
					img={tradeMeMock}
					alt={"project image cover"}
				/>

				<Body
					title={"Overview"}
					desc={`Trade Me had encountered an issue with their targeted marketing emails, as users were sometimes receiving emails featuring listings that were no longer active on the site due to being sold, closed, or removed. This caused frustration for users and resulted in a negative impact on their overall experience with the platform. The solution my team implemented to ensure that users only received emails with available listings was to migrate all listings to Google Firestore, a real-time cloud database. This allowed for real-time updates on listing events to be communicated through a specific marketing API, which ensured that only available listings were included in promotional emails.
          `}
					desc1={`Part of my role as a developer was to implement the update flow in Firestore for events being emitted from the monolith. This involved migrating all of Trade Me's listings to Firestore and integrating the database with the API. This ensured that any listing that was closed, sold, or removed from the platform was immediately reflected in the database and subsequently removed from the marketing emails sent out. I then extended the API by adding a time-to-live (TTL) for listings stored in the API, which ensured that outdated listings were removed from the API after a set period of time. I also implemented listing order preservation on request, which allowed the Marketing team to specify the order in which listings should appear in the email. `}
					desc2={`By implementing this solution, Trade Me was able to provide a better experience for its users, ensuring that only relevant and available listings were included in marketing emails. The migration to Firestore and integration with the marketing API was a successful solution to Trade Me's problem with outdated and irrelevant listings in targeted emails, providing a personalised, effortless experience for its members while optimising its marketing strategy.`}
				/>
				<Body title={"Tools"} />
				<div className="grid-container">
					<ul>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							C#
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							.NET Core
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							REST APIs
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Google Firestore
						</li>
					</ul>
				</div>
				<div
					style={{
						width: "150px",
						paddingTop: "2rem",
					}}
				>
					<FullButton
						title="Back"
						border={false}
						action={() => {
							navigate(-1);
						}}
					/>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	padding: 10% 10%;
	width: 100%;

	.grid-container {
		margin-top: -40px;
		display: grid;
	}
	li {
		line-height: 3.5rem;
	}
	span {
		color: #bfaa8c;
	}
`;

const HeaderInfo = styled.div``;
