import React from 'react'

export default function Title(props) {
  return (
    <div>
      <h1 className="font40 extraBold">
        {props.title}
      </h1>
      <br></br>
      <p className="font18 extraBold">
        {props.desc1}
      </p>
      <br></br>
      <p className="font16 regular">
        {props.desc2}
      </p>
      <br></br>
      <p className="font16 regular warning">
        {props.warning}
      </p>
    </div>
  )
}
