import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Components
import Title from "../components/elements/Title";
import FullButton from "../components/buttons/FullButton";
import Body from "../components/elements/Body";

export default function WeatherApp() {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Wrapper className="whiteBg">
			<div className="container">
				<HeaderInfo>
					<Title
						title="Weather App"
						desc1="A forecasting application that provides real-time and 7-day weather forecasts for locations all over the world delivered over a clean and intuitive user interface."
						desc2="Sure to be adored by weather enthusiasts and casual weather checkers alike."
					/>
				</HeaderInfo>
				{/* <Images
          img={weatherAppMobile}
          alt={'mockup image'}
        /> */}
				<ButtonsRow className="flexNullCenter">
					<div style={{ width: "190px" }}>
						<a
							href="https://weatherforecastbyhalmai.netlify.app/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="View"
								border={true}
							/>
						</a>
					</div>
					<div
						style={{
							width: "190px",
							marginLeft: "15px",
						}}
					>
						<a
							href="https://github.com/halmai-mcrae/weather-app"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FullButton
								title="Code"
								border={false}
							/>
						</a>
					</div>
				</ButtonsRow>
				<Body
					title={"Overview"}
					desc={`GeoDB Cities API was used to implement an auto-complete search bar, making it easier for users to find locations. Weather data is retrieved using the OpenWeather API through RESTful API architecture and includes current conditions, hourly forecasts, and 7-day forecasts. Styling is done with Vanilla CSS for a clean, modern look that's easy to maintain.`}
				/>
				<Body title={"Tools"} />
				<div className="grid-container">
					<ul>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							React
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							JavaScript
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							Netlify Cli
						</li>
						<li>
							<span className="fas fa-caret-right"></span>{" "}
							REST APIs
						</li>
					</ul>
				</div>

				<div
					style={{
						width: "150px",
						paddingTop: "2rem",
					}}
				>
					<FullButton
						title="Back"
						border={false}
						action={() => {
							navigate(-1);
						}}
					/>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	padding: 10% 10%;
	width: 100%;

	.grid-container {
		margin-top: -40px;
		display: grid;
	}
	li {
		line-height: 3.5rem;
	}
	span {
		color: #bfaa8c;
	}
`;

const HeaderInfo = styled.div``;

const ButtonsRow = styled.div`
  margin-top: 5%;
  @media (max-width: 860px) {
    justify - content: space-evenly;
  }
`;
