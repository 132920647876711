import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import styled from "styled-components";

export default function Services() {
	return (
		<div>
			<Wrapper id="about" className="container">
				<HeaderInfo>
					<h1 className="font40 bold">About</h1>
					<p className="font15 regular">
						Passionate about cloud computing,
						human-centered design, accessibility,
						and APIs. Currently, I’m focused on
						building mediation services and CI/CD
						pipelines in the Integration domain at
						ANZ.
						<br />
						<br />
						Although I’m not currently looking for
						any new opportunities, if you'd like
						to chat, or have something you think I
						could help with then please
						<Link
							activeclass="active"
							to="/#contact"
							spy={"true"}
							smooth={true}
							offset={-80}
						>
							{" "}
							get in touch with me.{" "}
						</Link>
					</p>
					<div className="font15">
						<br />
						<p>
							Technologies I&apos;ve been working
							with recently
						</p>
						<br />
						<div className="grid-container font18">
							<ul>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									Typescript
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									.NET
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									React
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									DevOps
								</li>
							</ul>
							<ul>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									Node.js
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									RESTful APIs
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									CI / CD
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									Microservices
								</li>
							</ul>
							<ul>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									C#
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									AWS
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									Micosoft Azure
								</li>
								<li>
									<span className="fas fa-caret-right"></span>{" "}
									Google Cloud
								</li>
							</ul>
						</div>
					</div>
				</HeaderInfo>
			</Wrapper>
		</div>
	);
}

const Wrapper = styled.section`
	width: 100%;
	min-height: 100vh;
	padding: 4% 4%;

	h1 {
		margin-bottom: 1.5rem;
	}

	a {
		text-decoration: underline !important;
	}
	@media (max-width: 960px) {
		padding: 10% 10%;
	}
	.grid-container {
		display: grid;
		grid-template-columns: repeat(
			3,
			1fr
		); // 3 items across by default
		grid-column-gap: 2rem;
		max-width: 100%;
	}

	li {
		line-height: 3.5rem;
	}

	span {
		color: #bfaa8c;
	}

	@media (max-width: 768px) {
		.grid-container {
			grid-template-columns: repeat(
				2,
				1fr
			); // 2 items across on smaller screens
		}
	}
`;

const HeaderInfo = styled.div`
	@media (max-width: 960px) {
		text-align: left;
	}
`;
